import { ALayoutSpacer, ATextTertiary } from 'component_lib';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import { Column } from '../layout/Column';
import { Row } from '../layout/Row';
import { browserPath } from '../../common/path';
import { IAppStore, IOrderItem, OrderStatusId } from '../../common/type';
import { useT, TranslationFunc } from '../../helper/i18n';
import { TextLink, LinkTertiary } from '../link/Link';
import { track, TrackingEvents } from '../../helper/tracking';
import { TableLabel, TableText } from './TableText';
import {formatPrice} from "../../helper/formatPrice";

interface IProps extends IOrderItem {
  email: string;
  includeReturnColumn?: boolean;
  orderStatus?: string;
}

const trackRequestStarted = (
  type: string,
  orderDate: string,
  storeOrderNumber: string,
  productVersionOptionId: string,
  email: string,
) => {
  track(
    TrackingEvents.returnStarted,
    {
      order: {
        order_date: orderDate,
        store_orders: [{ store_order_number: storeOrderNumber }],
      },
      product: {
        product_version_option_id: productVersionOptionId,
      },
      ui: { type },
    },
    email,
  );
};

const processReturnStatus = (
  productCategoryId: string,
  returnStatus: number | null,
  storeOrderId: string,
  productStoreOrderId: string,
  returnId: string | null,
  index: string,
  orderDate: string,
  productVersionOptionId: string,
  storeOrderNumber: string,
  storeOrderStatus: IOrderItem['storeOrderStatus'],
  t: TranslationFunc,
  orderStatus?: string,
  email?: string,
) => {
  const returnRestrictedCategories = useSelector((state: IAppStore) => state.returnRestrictedCategories);
  if (orderStatus === OrderStatusId.cancelled || storeOrderStatus.order_status_id === OrderStatusId.cancelled) {
    return <TableText>{t('ordersAndShipping::orderCancelled')}</TableText>;
  }

  if (returnStatus === 2) {

    return (
      <span style={{ cursor: 'pointer' }}>
        <TextLink
          onClick={() =>
            trackRequestStarted('Request Return', orderDate, storeOrderNumber, productVersionOptionId, email || '')
          }
          underline
          href={browserPath.printReturn(returnId as string)}
        >
          {t('ordersAndShipping::returnRequested')}
        </TextLink>
      </span>
    );
  }

  if (returnRestrictedCategories.includes(productCategoryId)) {
    return <ATextTertiary>{t('ordersAndShipping::returnIneligible')}</ATextTertiary>;
  }
  if (returnStatus === 1) {
    const href = browserPath.newReturnWarning(storeOrderId, productStoreOrderId, index);
    return (
      <LinkTertiary
        onClick={() =>
          trackRequestStarted('Request Return', orderDate, storeOrderNumber, productVersionOptionId, email || '')
        }
        to={href}
        underline
      >
        {t('ordersAndShipping::returnRequest')}
      </LinkTertiary>
    );
  }
  if (returnStatus === 3) {
    return <TableText>{t('ordersAndShipping::returnExpired')}</TableText>;
  }
  return <TableText>{t('ordersAndShipping::returnAfterDelivery')}</TableText>;
};

/* Component */
export const TableOrderDetailsRowInfo = (props: IProps) => {
  const { t } = useT();
  const {
    brand,
    categoryId,
    currency,
    email,
    includeReturnColumn,
    index,
    itemColor,
    model,
    modelNumber,
    orderDate,
    orderStatus,
    price,
    productStoreOrderId,
    productVersionOptionId,
    quantity,
    returnId,
    returnStatus,
    size,
    sizeType,
    storeOrderId,
    storeOrderNumber,
    storeOrderStatus,
  } = props;

  return (
    <>
      <Column flex={3} paddingRight={'20px'}>
        <MediaQuery maxWidth={1023}>
          <Row>
            <TableLabel fontWeight={'600'}>{t('ordersAndShipping::description')}</TableLabel>
            <ALayoutSpacer mobileHeight={'39px'} />
          </Row>
        </MediaQuery>
        <Row>
          <TableText dir="ltr">{brand}</TableText>
        </Row>
        <Row>
          <TableText dir="ltr">{model}</TableText>
        </Row>
        <Row>
          <TableText dir="ltr">{modelNumber}</TableText>
        </Row>
        <Row>
          <TableText dir="ltr">{storeOrderNumber}</TableText>
        </Row>
      </Column>
      <ALayoutSpacer mobileHeight={'15px'} />
      <Column flex={1.5} paddingRight={'20px'}>
        <Row alignItems="center">
          <MediaQuery maxWidth={1023}>
            <TableLabel marginRight={'5px'}>{t('ordersAndShipping::color')}: </TableLabel>
          </MediaQuery>
          <TableText dir="ltr">{itemColor}</TableText>
        </Row>
      </Column>
      <Column flex={1.5} paddingRight={'20px'}>
        <Row alignItems="center">
          <MediaQuery maxWidth={1023}>
            <TableLabel marginRight={'5px'}>{t('ordersAndShipping::size')}: </TableLabel>
          </MediaQuery>
          <TableText dir="ltr">
            {sizeType} {size}
          </TableText>
        </Row>
      </Column>
      <Column flex={1.5} paddingRight={'20px'}>
        <Row alignItems="center">
          <MediaQuery maxWidth={1023}>
            <TableLabel marginRight={'5px'}>{t('ordersAndShipping::quantity')}: </TableLabel>
          </MediaQuery>
          <TableText dir="ltr">{quantity}</TableText>
        </Row>
      </Column>
      <Column flex={includeReturnColumn ? 1 : 0.5} paddingRight={'20px'}>
        <TableText dir="ltr">
          {currency} {formatPrice(price, currency)}
        </TableText>
      </Column>
      {includeReturnColumn ? (
        <Column flex={2} paddingRight={'20px'}>
          <ALayoutSpacer mobileHeight={'15px'} />

          {processReturnStatus(
            categoryId,
            returnStatus,
            storeOrderId,
            productStoreOrderId,
            returnId,
            index,
            orderDate,
            productVersionOptionId,
            storeOrderNumber,
            storeOrderStatus,
            t,
            orderStatus,
            email,
          )}
        </Column>
      ) : null}
    </>
  );
};
