import axios from 'axios';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import i18next from 'i18next';
import { track, TrackingEvents, trackPeople } from '../../helper/tracking';
import { isNotEmpty, isNotEmptyString, isValidPassword } from '../../helper/validate';
import {
  initI18n,
  i18nNamespaces,
  loadI18nResources,
  languagesConfig,
  getActiveLangOrFallback,
} from '../../helper/i18n';
import { apiPath, browserPath } from '../path';
import {
  API_ERROR,
  IAppStore,
  IDropdownOption,
  IOrderDetail,
  IOrderItem,
  IPagination,
  IProfilePayload,
  IReturnDocuments,
  IReturnReason,
  IReturnReasonSelected,
  IReturnSummary,
  ReturnReason,
  ReturnType,
} from '../type';
import { CLEAR, EMPTY_ACTION, INIT, TOGGLE, UPDATE } from './type';

export const emptyAction: ActionCreator<Action> = () => ({ type: EMPTY_ACTION });

export const updateCountry: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.COUNTRY,
  value,
});

export const updateMktCountry: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.MKT_COUNTRY,
  value,
});

export const updateBasePathWeb: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.BASE_PATH_WEB,
  value,
});

export const updatePaperlessReturnFlag: ActionCreator<Action> = (value: boolean) => ({
  type: UPDATE.PAPERLESS_RETURN_FLAG,
  value,
});

export const updateReturnDocuments: ActionCreator<Action> = (value: IReturnDocuments) => ({
  type: UPDATE.RETURN_DOCUMENTS,
  value,
});

export const updateShipConfirmationNumber: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.SHIP_CONFIRMATION_NUMBER,
  value,
});

export const updateReturnReasonsSelected: ActionCreator<Action> = (value: IReturnReasonSelected[]) => ({
  type: UPDATE.RETURN_REASONS_SELECTED,
  value,
});

export const clearReturnDocuments: ActionCreator<Action> = () => ({ type: CLEAR.RETURN_DOCUMENTS });

export const updateReturnDocumentsError: ActionCreator<Action> = () => ({
  type: UPDATE.RETURN_DOCUMENTS_ERROR,
});

export const clearReturnDocumentsError: ActionCreator<Action> = () => ({
  type: CLEAR.RETURN_DOCUMENTS_ERROR,
});

export const updateReturnRequestExpiredError: ActionCreator<Action> = () => ({
  type: UPDATE.RETURN_REQUEST_EXPIRED_ERROR,
});

export const clearReturnRequestExpiredError: ActionCreator<Action> = () => ({
  type: CLEAR.RETURN_REQUEST_EXPIRED_ERROR,
});

export const updateReturnId: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.RETURN_ID,
  value,
});

export const setReturnType: ActionCreator<Action> = (value: ReturnType) => ({
  type: UPDATE.RETURN_SHIPPING_TYPE,
  value,
});

export const clearReturnId: ActionCreator<Action> = () => ({ type: CLEAR.RETURN_ID });

export const updateShippingDates: ActionCreator<Action> = (value: IDropdownOption[]) => ({
  type: UPDATE.SHIPPING_DATES,
  value,
});

export const updateShippingHours: ActionCreator<Action> = (value: IDropdownOption[]) => ({
  type: UPDATE.SHIPPING_HOURS,
  value,
});

export const updateCustomerGender: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (value: string) => (dispatch: Dispatch) => {
    dispatch(clearCustomerGenderError());
    return dispatch({ type: UPDATE.CUSTOMER_GENDER, value });
  };

export const clearFirstNameError: ActionCreator<Action> = () => ({ type: CLEAR.FIRST_NAME_ERROR });

export const clearLastNameError: ActionCreator<Action> = () => ({ type: CLEAR.LAST_NAME_ERROR });

export const clearCustomerGenderError: ActionCreator<Action> = () => ({
  type: CLEAR.CUSTOMER_GENDER_ERROR,
});

export const updateFirstName: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (value: string) => (dispatch: Dispatch) => {
    dispatch(clearFirstNameError());
    return dispatch({ type: UPDATE.FIRST_NAME, value });
  };

export const updateLastName: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (value: string) => (dispatch: Dispatch) => {
    dispatch(clearLastNameError());
    return dispatch({ type: UPDATE.LAST_NAME, value });
  };

export const updateFirstNameError: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.FIRST_NAME_ERROR,
  value,
});

export const updateLastNameError: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.LAST_NAME_ERROR,
  value,
});

export const updateConfirmPassword: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (value: string) => (dispatch: Dispatch) => {
    dispatch(clearConfirmPasswordError());
    return dispatch({ type: UPDATE.CONFIRM_PASSWORD, value });
  };

export const updateCurrentPassword: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (value: string) => (dispatch: Dispatch) => {
    dispatch(clearCurrentPasswordError());
    return dispatch({ type: UPDATE.CURRENT_PASSWORD, value });
  };

export const updateNewPassword: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (value: string) => (dispatch: Dispatch) => {
    dispatch(clearNewPasswordError());
    dispatch(clearConfirmPasswordError());
    const { isMinLength, isValid } = isValidPassword(value);
    dispatch({ type: UPDATE.NEW_PASSWORD_INDICATOR, value: { isMinLength, isValid } });
    return dispatch({ type: UPDATE.NEW_PASSWORD, value });
  };

export const updateConfirmPasswordError: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.CONFIRM_PASSWORD_ERROR,
  value,
});

export const updateCurrentPasswordError: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.CURRENT_PASSWORD_ERROR,
  value,
});

export const updateNewPasswordError: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.NEW_PASSWORD_ERROR,
  value,
});

export const updateCustomerGenderError: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.CUSTOMER_GENDER_ERROR,
  value,
});

export const clearConfirmPassword: ActionCreator<Action> = () => ({ type: CLEAR.CONFIRM_PASSWORD });

export const clearCurrentPassword: ActionCreator<Action> = () => ({ type: CLEAR.CURRENT_PASSWORD });

export const clearNewPassword: ActionCreator<Action> = () => ({ type: CLEAR.NEW_PASSWORD });

export const clearConfirmPasswordError: ActionCreator<Action> = () => ({
  type: CLEAR.CONFIRM_PASSWORD_ERROR,
});

export const clearCurrentPasswordError: ActionCreator<Action> = () => ({
  type: CLEAR.CURRENT_PASSWORD_ERROR,
});

export const clearNewPasswordError: ActionCreator<Action> = () => ({
  type: CLEAR.NEW_PASSWORD_ERROR,
});

export const updateOrders: ActionCreator<Action> = (value: IOrderDetail[]) => ({
  type: UPDATE.ORDERS,
  value,
});

export const updatePagination: ActionCreator<Action> = (value: IPagination) => ({
  type: UPDATE.PAGINATION,
  value,
});

export const updateEmail: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.EMAIL,
  value,
});

export const clearProcessingModalText: ActionCreator<Action> = () => ({
  type: CLEAR.PROCESSING_MODAL_TEXT,
});

export const updateProcessingModalText: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.PROCESSING_MODAL_TEXT,
  value,
});

export const clearProfileFormSuccessMessage: ActionCreator<Action> = () => ({
  type: CLEAR.PROFILE_FORM_MESSAGE,
});

export const updateProfileFormSuccessMessage: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.PROFILE_FORM_MESSAGE,
  value,
});

export const toggleReturnPolicyModal: ActionCreator<Action> = () => ({
  type: TOGGLE.RETURN_POLICY_MODAL,
});

export const updateReturnItem: ActionCreator<Action> = (value: IOrderItem) => ({
  type: UPDATE.RETURN_ITEM,
  value,
});

export const clearReturnItem: ActionCreator<Action> = () => ({ type: CLEAR.RETURN_ITEM });

export const updateReturnReasonId: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.RETURN_REASON_ID,
  value,
});

export const updateReturnSummary: ActionCreator<Action> = (value: IReturnSummary) => ({
  type: UPDATE.RETURN_SUMMARY,
  value,
});

export const loadI18n: ActionCreator<ThunkAction<Promise<Action>, IAppStore, null, Action>> =
  () => async (dispatch: Dispatch) => {
    const namespaces = [
      i18nNamespaces.common,
      i18nNamespaces.returns,
      i18nNamespaces.ordersAndShipping,
      i18nNamespaces.profile,
    ];

    let i18nextInstance: typeof i18next;

    try {
      // get lang and country from legacy-api session
      const response = await axios.get(apiPath.getPreferredLanguage());
      const {
        data: { preferred_lang_iso_code_2, mkt_country_code },
      } = response;

      const { resources } = await loadI18nResources({
        countryIsoCode2: mkt_country_code,
        languagesConfig,
        langIsoCode2: preferred_lang_iso_code_2,
        namespaces,
        i18nDataFileImporter: async (relativePath: string) => {
          const importedFile = await import(`../../i18n_data/dist/json/${relativePath}`);
          return { ...importedFile };
        },
      });

      const displayedLanguage = getActiveLangOrFallback(languagesConfig, preferred_lang_iso_code_2);
      document.documentElement.lang = displayedLanguage;
      if (languagesConfig.languages[displayedLanguage]?.dir === 'rtl') {
        document.documentElement.dir = 'rtl';
      }

      i18nextInstance = initI18n({
        countryIsoCode2: mkt_country_code,
        langIsoCode2: preferred_lang_iso_code_2,
        languagesConfig,
        namespaces,
        resources,
      });

      dispatch(updateMktCountry(mkt_country_code));
    } catch (e) {
      console.log(e);
    }

    return dispatch({
      type: INIT.I18N,
      value: { i18nextInstance },
    });
  };

export const updateReturnRestrictedCategories: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.RETURN_RESTRICTED_CATEGORIES,
  value,
});

export const clearReturnSummary: ActionCreator<Action> = () => ({ type: CLEAR.RETURN_SUMMARY });

export const setDefaultReturnReasonId: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  () => (dispatch: Dispatch, getState: () => IAppStore) => {
    const { returnReasonId } = getState();

    if (returnReasonId) {
      return updateReturnReasonId(returnReasonId);
    }
    return dispatch(updateReturnReasonId(ReturnReason.changedMind));
  };

export const submitReturnReasonId: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (storeOrderId: string, productStoreOrderId: string, item: IOrderItem, history: any) =>
  (dispatch: Dispatch, getState: () => IAppStore) => {
    const { returnReasonId } = getState();

    axios.post(apiPath.returnsReason(storeOrderId, productStoreOrderId), { returnReasonId }).then((res) => {
      dispatch(updateReturnItem(item));
      dispatch(updateReturnSummary(res.data));
      dispatch(clearProcessingModalText());
      history.push(browserPath.returnOverview(storeOrderId, productStoreOrderId, item.index));
    });

    return dispatch(updateProcessingModalText('processing'));
  };

export const updateReturnReasons: ActionCreator<Action> = (value: IReturnReason[]) => ({
  type: UPDATE.RETURN_REASONS,
  value,
});

export const updateReturnShippingDate: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.RETURN_SHIPPING_DATE,
  value,
});

export const updateReturnShippingHour: ActionCreator<Action> = (value: string) => ({
  type: UPDATE.RETURN_SHIPPING_HOUR,
  value,
});

export const validateProfile: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  () => (dispatch: ThunkDispatch<IAppStore, null, Action>, getState: () => IAppStore) => {
    const {
      currentPassword,
      currentPasswordError,
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      newPassword,
      newPasswordError,
      genderError,
    } = getState();

    let validated = true;

    dispatch(clearProfileFormSuccessMessage());

    if (currentPasswordError || firstNameError || lastNameError || newPasswordError || genderError) {
      validated = false;
    }

    if (!isNotEmptyString(firstName)) {
      validated = false;
      dispatch(updateFirstNameError('firstName'));
    }

    if (!isNotEmptyString(lastName)) {
      validated = false;
      dispatch(updateLastNameError('lastName'));
    }

    if (isNotEmpty(newPassword) && !isNotEmptyString(currentPassword)) {
      validated = false;
      dispatch(updateCurrentPasswordError('currentPassword'));
    }

    if (!isNotEmptyString(newPassword) && isNotEmpty(currentPassword)) {
      validated = false;
      dispatch(updateNewPasswordError('newPasswordEmpty'));
    }

    const { isMinLength, isValid } = isValidPassword(newPassword);
    if (isNotEmpty(newPassword) && (!isMinLength || !isValid)) {
      validated = false;
      dispatch({ type: UPDATE.NEW_PASSWORD_INDICATOR, value: { isMinLength, isValid } });
    }

    if (isNotEmpty(newPassword) && newPassword === currentPassword) {
      validated = false;
      dispatch(updateNewPasswordError('newPassword'));
    }

    if (!validated) {
      return emptyAction();
    }

    submitProfile(dispatch, getState);
    return emptyAction();
  };

export const validateConfirmPassword: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  () => (dispatch: ThunkDispatch<IAppStore, null, Action>, getState: () => IAppStore) => {
    const { confirmPassword, newPassword } = getState();

    if (newPassword !== confirmPassword) {
      dispatch(updateConfirmPasswordError('confirmPassword'));
    }

    return emptyAction();
  };

export const submitProfile = (dispatch: ThunkDispatch<IAppStore, null, Action>, getState: () => IAppStore) => {
  const { confirmPassword, currentPassword, firstName, gender, lastName, newPassword } = getState();

  let payload: IProfilePayload = {
    firstName,
    lastName,
  };
  if (gender) {
    payload = { ...payload, gender };
  }
  if (newPassword) {
    const passwords = {
      confirmPassword,
      currentPassword,
      newPassword,
    };
    payload = { ...payload, ...passwords };
  }

  axios.post(apiPath.profile(), payload).then(
    () => {
      dispatch(clearConfirmPassword());
      dispatch(clearCurrentPassword());
      dispatch(clearNewPassword());
      dispatch(updateProfileFormSuccessMessage('profile::save:success'));
      trackPeople('set', '$name', `${firstName} ${lastName}`, getState().email);
      trackPeople('set', 'gender', gender === 'F' ? 'female' : 'male', getState().email);
      track(TrackingEvents.profileEdited, undefined, getState().email);
    },
    (err) => {
      switch (err.response.data.internalCode) {
        case API_ERROR.WRONG_CURRENT_PASSWORD:
          dispatch(updateCurrentPasswordError(err.response.data.message));
          break;
        default:
          break;
      }
    },
  );
};

export const updateAppStore: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (offset?: string) => (dispatch: ThunkDispatch<IAppStore, null, Action>) => {
    const ordersPromise = axios.get(apiPath.orders(), { params: { offset } });
    const profilePromise = axios.get(apiPath.profile());
    const defaultPromise = axios.get(apiPath.defaults());
    const returnRestrictedCategoriesPromise = axios.get(apiPath.getReturnRestrictedCategories());
    Promise.all([ordersPromise, profilePromise, returnRestrictedCategoriesPromise, defaultPromise])
      .then((data) => {
        const ordersData = data[0].data;
        const profileData = data[1].data;
        const returnRestrictedCategoriesData = data[2].data;
        const defaultData = data[3].data;
        const { basePathWeb, country, orders, returnReasons, email } = ordersData;
        const { firstName, lastName, gender } = profileData.user;
        const { paperlessReturnFlag } = defaultData

        dispatch(updateCountry(country));
        dispatch(updateOrders(orders));
        dispatch(updatePagination({total: orders.total}));
        dispatch(updateEmail(email));
        dispatch(updateBasePathWeb(basePathWeb));
        dispatch(updateReturnReasons(returnReasons));
        dispatch(clearProcessingModalText());
        dispatch(updateReturnRestrictedCategories(returnRestrictedCategoriesData));
        dispatch(updatePaperlessReturnFlag(paperlessReturnFlag));

        dispatch(updateFirstName(firstName));
        dispatch(updateLastName(lastName));
        dispatch(updateCustomerGender(gender));
      })
      .catch((err) => {
        console.log({err});
        dispatch(clearProcessingModalText());
        if (err.response.status === 401) {
          axios.get(apiPath.defaults()).then((res) => {
            const { basePathWeb } = res.data;
            window.location.href = `${basePathWeb}/en/signup?redirect=myaccount`;
          });
        }
      });

    return dispatch(updateProcessingModalText('loading'));
  };

export const fetchOrders: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (offset: number) => (dispatch: ThunkDispatch<IAppStore, null, Action>) => {
    axios
      .get(apiPath.orders(), { params: { offset } })
      .then((response) => {
        const {
          data: { orders },
        } = response;
        dispatch(updateOrders(orders));
        dispatch(updatePagination({total: orders.total}));
        dispatch(clearProcessingModalText());
      })
      .catch((err) => {
        dispatch(clearProcessingModalText());
        console.log({err});
        if (err.response.status === 401) {
          axios.get(apiPath.defaults()).then((res) => {
            const { basePathWeb } = res.data;
            window.location.href = `${basePathWeb}/en/signup?redirect=myaccount`;
          });
        }
      });

    return dispatch(updateProcessingModalText('loading'));
  };

export const getAvailableShippingDates: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (storeOrderId: string) => (dispatch: Dispatch) => {
    axios.get(apiPath.returnsPickUpDates(storeOrderId)).then((res) => {
      const { shippingDates, shippingHours } = res.data;
      // need to add error handling
      dispatch(updateReturnShippingDate(shippingDates[0].value));
      dispatch(updateReturnShippingHour(shippingHours[0].value));
      dispatch(updateShippingDates(shippingDates));
      dispatch(updateShippingHours(shippingHours));
    });

    return emptyAction();
  };

export const submitReturnShippingDate: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (history: any) => (dispatch: Dispatch, getState: () => IAppStore) => {
    const {
      returnItem: { storeOrderId, productStoreOrderId },
      returnShippingDate,
      returnShippingHour,
      returnShippingType,
      returnReasonId,
    } = getState();


    const returnTypeId = returnShippingType === ReturnType.DROP_OFF ? 1 : 2;

    axios
      .post(apiPath.returnsFinalizeProcess(storeOrderId, productStoreOrderId), {
        returnShippingDate,
        returnShippingHour,
        returnTypeId,
        returnReasonId,
      })
      .then((res) => {
        const { returnDocuments, returnId } = res.data;

        dispatch(updateReturnId(returnId));
        dispatch(clearProcessingModalText());
        dispatch(updateReturnDocuments(returnDocuments));
        dispatch(clearReturnDocumentsError());
        dispatch(clearReturnRequestExpiredError());

        history.push(browserPath.printReturn(returnId));

        axios.get(apiPath.orders()).then((response) => dispatch(updateOrders(response.data.orders)));
      })
      .catch((err) => {
        if (err.response.data.code === API_ERROR.RETURN_EXPIRED) {
          dispatch(updateReturnRequestExpiredError());
        } else {
          dispatch(updateReturnDocumentsError());
        }
        dispatch(clearReturnDocuments());
        dispatch(clearProcessingModalText());
        history.push(browserPath.printReturn());
      });

    return dispatch(updateProcessingModalText('bookingShipment'));
  };

export const clearReturnData: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  () => (dispatch: Dispatch) => {
    dispatch(clearReturnDocuments());
    dispatch(clearReturnId());
    dispatch(clearReturnItem());
    dispatch(updateReturnReasonId(ReturnReason.changedMind));
    dispatch(clearReturnDocumentsError());
    return dispatch(clearReturnSummary());
  };

export const getTrackShipmentData: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (storeOrderId: string) => (dispatch: Dispatch) => {
    axios.get(apiPath.trackOrder(storeOrderId)).then((res) => {
      const { message, trackShipmentData } = res.data;

      if (message) {
        dispatch({ type: UPDATE.TRACK_SHIPMENT_MESSAGE, value: message });
      } else {
        dispatch({ type: UPDATE.TRACK_SHIPMENT_DATA, value: trackShipmentData });
      }

      return dispatch(clearProcessingModalText());
    });

    return dispatch(updateProcessingModalText('requestingShipmentStatus'));
  };

export const clearTrackShipment: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR.TRACK_SHIPMENT_DATA });
    return dispatch({ type: CLEAR.TRACK_SHIPMENT_MESSAGE });
  };

export const getReturnDetails: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (returnId: string) => (dispatch: Dispatch, getState: () => IAppStore) => {
    const { orders} = getState();
    let returnItem;
    for (const order of orders) {
      for (const item of order.items) {
        if (item.returnId === returnId) {
          returnItem = item
          break
        }
      }
    }

    const returnType = (returnTypeId: string) => (returnTypeId === '1' ? ReturnType.DROP_OFF : ReturnType.PICK_UP);
    axios
      .get(apiPath.returns(returnId))
      .then((res) => {
        dispatch(updateReturnId(returnId));
        dispatch(updateReturnDocuments(res.data.returnDocuments));
        dispatch(updateShipConfirmationNumber(res.data.shipDispatchConfirmationNumber));
        dispatch(updateReturnReasonsSelected(res.data.reasons));
        dispatch(clearReturnDocumentsError());
        dispatch(clearProcessingModalText());
        dispatch(setReturnType(returnType(res.data.returnTypeId)));
        if (returnItem) {
          dispatch(updateReturnItem(returnItem))
        }
      })
      .catch(() => {
        dispatch(updateReturnDocumentsError());
        dispatch(clearReturnDocuments());
        dispatch(clearProcessingModalText());
      });

    return dispatch(updateProcessingModalText('retrieveReturn'));
  };

export const logout: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  () => (_: Dispatch, getState: () => IAppStore) => {
    window.location.href = `${getState().basePathWeb}/logout`;
    return emptyAction();
  };

export const downloadInvoices: ActionCreator<ThunkAction<Action, IAppStore, null, Action>> =
  (orderId: string, orderNumber: string, email: string) => (_: Dispatch) => {
    axios.get(apiPath.ordersInvoices(orderId)).then((res) => {
      window.location.href = res.data.invoice_url;
      track(
        TrackingEvents.invoiceDownloaded,
        {
          order: {
            invoice_number: res.data.invoice_numbers,
            order_number: orderNumber,
          },
        },
        email,
      );
    });

    return emptyAction();
  };
